import React from 'react';

import { Box, Container, Paper, Skeleton, Stack } from '@mui/material';
import setupInteractiveIframe from '../interactive-iframe';

const CustomEffect = () => {
  React.useEffect(
    () => {
      setupInteractiveIframe({
        // Absolute iframe src (with protocol, without trailing slash)
        basePath: 'https://climadiag-commune-demo.netlify.app',

        iframeSelector: 'iframe#iframe', // CSS selector of iframe

        verticalPaddingSelectors: {
          top: 'main > header.MuiBox-root', // Sticky header
          // bottom: 'footer', // Sticky footer
        },
      });
    },
    [],
  );

  return null;
};

const IframePage = () => (
  <Box
    component="main"
    sx={{
      pt: '180px',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '50px solid #145e9a',
    }}
  >
    <CustomEffect />
    <Box
      component="header"
      sx={{
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '180px',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
      }}
    >
      <Container sx={{ pt: 1.5 }}>
        <Stack direction="row" spacing={2}>
          <Box
            component="img"
            src="https://meteofrance.com/themes/custom/theme_public/dist/img/marianne.svg"
            alt="logo"
            sx={{ height: '80px', width: 'auto' }}
          />
          <Box
            component="img"
            src="https://meteofrance.com/sites/meteofrance.com/files/logo/LOGO_MF.png"
            alt="logo"
            sx={{ height: '80px', width: '80px' }}
          />
        </Stack>
        <Stack direction="row" spacing={5} sx={{ mt: 2 }} justifyContent="space-between">
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 90 }} />
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 110 }} />
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 130 }} />
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 80 }} />
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 130 }} />
          <Skeleton variant="text" animation={false} sx={{ fontSize: '1em', width: 100 }} />
          <Skeleton
            variant="text"
            animation={false}
            sx={{ fontSize: '1em', width: 20, bgcolor: 'primary.main', opacity: 0.2 }}
          />
        </Stack>
      </Container>

      <Box
        sx={{
          height: '36px',
          background: '#036ba1',
          marginTop: 'auto',
        }}
      >
        <Container>
          <Skeleton
            variant="text"
            animation={false}
            sx={{ fontSize: '1em', width: 150, bgcolor: 'rgba(250 250 250 / 0.2)', mt: 0.8 }}
          />
        </Container>
      </Box>
    </Box>

    <Box
      sx={{
        flex: 1,
        background: '#eeeeee',
        py: '35px',
      }}
    >
      <Box
        component="iframe"
        id="iframe"
        src="https://climadiag-commune-demo.netlify.app"
        sx={{
          mx: 'auto',
          display: 'block',
          width: '90%',
          height: '800px',
          border: 0,
        }}
      />

      <Container sx={{ pb: 10 }}>
        <Paper
          elevation={6}
          sx={{
            mt: 10,
            p: 3,
            borderRadius: 3,
          }}
        >
          <Skeleton
            animation={false}
            sx={{
              width: 200,
              bgcolor: 'secondary.main',
              fontSize: '1.5em',
              opacity: 0.2,
              m: 'auto',
            }}
          />
        </Paper>

        <Skeleton
          animation={false}
          sx={{ bgcolor: 'secondary.main', width: '80%', maxWidth: 280, mt: 10, opacity: 0.4 }}
        />
        <Skeleton animation={false} sx={{ mt: 2 }} />
        <Skeleton animation={false} />

        <Skeleton animation={false} sx={{ mt: 4 }} />

        <Skeleton animation={false} sx={{ mt: 4 }} />
        <Skeleton animation={false} sx={{ width: '30%' }} />
      </Container>
    </Box>
  </Box>
);

export default IframePage;
